import { handlerRespAxiosMonitorCampaign } from "../../../../lib/handleResponse";
export default {
  //* QUERIES
  async GET_ALL_SEMANTIC({commit}, idCampaign) {
    try {
      const { data } = await handlerRespAxiosMonitorCampaign(
        "get",
        "/grafos-campaign/allsemantic",
        {
          id_campania: idCampaign
        }
      );
      const semantic = data.data;
      commit("SET_ALL_SEMANTIC", semantic);
    } catch (error) {
      console.log("[Error] in function: GET_ALL_SEMANTIC ", error);
      throw error;
    }
  },
  async GET_ALL_SEMANTIC_BY_SOCIAL_MEDIA(_, { idCampaign, rss }) {
    try {
      const { data } = await handlerRespAxiosMonitorCampaign(
        "get",
        "/grafos-campaign/allsemanticsXRedSocial",
        {
          id_campania: idCampaign,
          network: rss
        }
      );
      return data.data;
    } catch (error) {
      console.log("[Error] in function: GET_ALL_SEMANTIC_BY_SOCIAL_MEDIA ", error);
      throw error;
    }
  },
  async GET_ALL_SEMANTIC_BY_POST(_, idPost) {
    try {
      const { data } = await handlerRespAxiosMonitorCampaign(
        "get",
        "/grafos-campaign/allSemanticXPost",
        {
          id_post: idPost
        }
      );
      return data.data;
    } catch (error) {
      console.log("[Error] in function: GET_ALL_SEMANTIC_BY_POST ", error);
      throw error;
    }
  },
  async GET_ACTIONS(_, { idCampaign, rss }) {
    try {
      const { data } = await handlerRespAxiosMonitorCampaign(
        "get",
        "/grafos-campaign/getpost",
        {
          id_campania: idCampaign,
          redsocial: rss
        }
      );
      return data.data;
    } catch (error) {
      console.log("[Error] in function: GET_ALL_SEMANTIC_BY_POST ", error);
      throw error;
    }
  },
  async GET_OVER_ALL_SENTIMENT(_, { campaign_id, fechaini, fechafin, timezone_id }) {
    try {
      const { data } = await handlerRespAxiosMonitorCampaign(
        "get",
        "/grafos-campaign/getOverallSentimentGraph",
        {
          fechaini,
          fechafin,
          campaign_id,
          timezone_id
        }
      );
      return data.data;
    } catch (error) {
      console.log("[Error] in function: GET_OVER_ALL_SENTIMENT ", error);
      throw error;
    }
  },
  async GET_IMPACT_GRAPH(_, { id_campania, fechainicio, fechafin }) {
    try {
      const { data } = await handlerRespAxiosMonitorCampaign(
        "get",
        "/grafos-campaign/getImpactGraph",
        {
          fechainicio,
          fechafin,
          id_campania
        }
      );
      return data.data;
    } catch (error) {
      console.log("[Error] in function: GET_IMPACT_GRAPH ", error);
      throw error;
    }
  },
  async GET_SENTIMENT_GRAPH(_, { id, type, rss, fechaini, fechafin, timezone_id }) {
    try {
      const { data } = await handlerRespAxiosMonitorCampaign(
        "get",
        "/grafos-campaign/getsentimentGraph",
        {
          id, 
          type,
          rss,
          fechaini,
          fechafin,
          timezone_id
        }
      );
      return data.data;
    } catch (error) {
      console.log("[Error] in function: GET_SENTIMENT_GRAPH ", error);
      throw error;
    }
  },
  //* MUTATIONS
  /* async UPDATE_CAMPAIGN(_, { idCampaign, campaign }) {
    try {
      delete campaign.table;
      const { data } = await handlerRespAxiosMonitorCampaign(
        "put",
        `/campaign/${idCampaign}`,
        {
          data: campaign,
        }
      );
      return data.data;
    } catch (error) {
      console.log("[Error] in function: UPDATE_CAMPAIGN ", error);
      throw error;
    }
  }, */
};
