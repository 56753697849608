
export default {
  setLoader(state, loader) {
    state.loader = loader;
  },
  SHOW_MODAL_NO_AUTH(state) {
    state.isNoAuth = true;
  },
  HIDE_MODAL_NO_AUTH(state) {
    state.isNoAuth = false;
  },
  SET_MSG_NO_AUTH(state, msgNoAuth) {
    state.msgNoAuth = msgNoAuth;
  },
  SET_DRIVERS(state, drivers) {
    state.drivers = drivers;
  }
};
