import {
  GET_GEO_DRIVERS,
  FB_FAN_PAGE_CHECK,
  IG_PROFILE_PAGE_CHECK,
  LK_PROFILE_PAGE_CHECK,
  NEWSPAPER_DRIVERS,
  GET_OVERVIEWS,
  GET_CONTEXT_BY_ID,
  GEO_INTEREST_BY_COUNTRY
} from "../graphql/query";
import {
  ADD_GEO_KEYWORD,
  CREATE_CONTEXT,
  DELETE_CONTEXT,
  UPDATE_CONTEXT,
  ADD_KEYWORD,
  DELETE_KEYWORD,
  TOGGLE_ACTIVE_KEYWORD,
  TOGGLE_ACTIVE_KEYWORD_TWITTER
} from "../graphql/mutations";
import { handleResponse } from "../../../../lib/handleResponse";
export default {
  //* QUERIES
  async geoDrivers({ commit }, socialNetwork) {
    let geoDrivers = {};
    if (socialNetwork == "all") {
      const {
        data: {
          geoDrivers: { items: geoDriversFb },
        },
      } = await handleResponse("query", {
        query: GET_GEO_DRIVERS,
        variables: { social_network: "fb" },
      });
      const {
        data: {
          geoDrivers: { items: geoDriversTw },
        },
      } = await handleResponse("query", {
        query: GET_GEO_DRIVERS,
        variables: { social_network: "tw" },
      });
      const {
        data: {
          geoDrivers: { items: geoDriversIg },
        },
      } = await handleResponse("query", {
        query: GET_GEO_DRIVERS,
        variables: { social_network: "ig" },
      });
      const {
        data: {
          geoDrivers: { items: geoDriversLk },
        },
      } = await handleResponse("query", {
        query: GET_GEO_DRIVERS,
        variables: { social_network: "lk" },
      });

      geoDrivers = Object.assign({}, geoDriversFb.data);
      for (const country in geoDriversTw.data) {
        for (const category in geoDriversTw.data[country].categories) {
          for (const driver of geoDriversTw.data[country].categories[
            category
          ]) {
            geoDrivers[country].categories[category].push(driver);
          }
        }
      }
      for (const country in geoDriversIg.data) {
        for (const category in geoDriversIg.data[country].categories) {
          for (const driver of geoDriversIg.data[country].categories[
            category
          ]) {
            geoDrivers[country].categories[category].push(driver);
          }
        }
      }
      for (const country in geoDriversLk.data) {
        for (const category in geoDriversLk.data[country].categories) {
          for (const driver of geoDriversLk.data[country].categories[
            category
          ]) {
            geoDrivers[country].categories[category].push(driver);
          }
        }
      }
      commit("SET_GEO_DRIVERS", geoDrivers);
      commit("SET_GEO_DRIVERS_FB", geoDriversFb.data);
      commit("SET_GEO_DRIVERS_TW", geoDriversTw.data);
      commit("SET_GEO_DRIVERS_IG", geoDriversIg.data);
      commit("SET_GEO_DRIVERS_LK", geoDriversLk.data);
    } else {
      const {
        data: {
          geoDrivers: { items: geoDriversSocialMedia },
        },
      } = await handleResponse("query", {
        query: GET_GEO_DRIVERS,
        variables: { social_network: socialNetwork },
      });
      geoDrivers = geoDriversSocialMedia.data;
      commit(`SET_GEO_DRIVERS_${socialNetwork.toUpperCase()}`, geoDrivers);
    }
    return geoDrivers;
  },
  async CHECK_FB_PAGE(_, fanPage_url) {
    return await handleResponse("query", {
      query: FB_FAN_PAGE_CHECK,
      variables: fanPage_url,
    });
  },
  async CHECK_IG_PAGE(_, profilePage_url) {
    return await handleResponse("query", {
      query: IG_PROFILE_PAGE_CHECK,
      variables: profilePage_url,
    });
  },
  async CHECK_LK_PAGE(_, profilePage_url) {
    return await handleResponse("query", {
      query: LK_PROFILE_PAGE_CHECK,
      variables: profilePage_url,
    });
  },
  async GET_NEWSPAPER_LIST({ commit }) {
    return await handleResponse("query", {
      query: NEWSPAPER_DRIVERS,
      //fetchPolicy: "no-cache",
    });
  },
  async GET_CONTEXTS_BY_ACCOUNT({ commit }, { account_id }) {
    return await handleResponse("query", {
      query: GET_OVERVIEWS,
      variables: { account_id },
      fetchPolicy: "no-cache",
    });
  },
  async GET_CONTEXT_BY_ID({ commit }, { context_id }) {
    return await handleResponse("query", {
      query: GET_CONTEXT_BY_ID,
      variables: { context_id },
      fetchPolicy: "no-cache",
    });
  },
  async GET_INTERESTS_BY_COUNTRY(_, { country, interests }) {
    const { data } = await handleResponse("query", {
      query: GEO_INTEREST_BY_COUNTRY,
      variables: { 
       interest_ids: interests,
       country
      },
    });
    return data.getGeoInterests;
  },
  //========= END QUERIES =========
  //* MUTATIONS
  async CREATE_CONTEXT(
    { commit },
    {
      account_id,
      name,
      twitter_drivers,
      facebook_drivers,
      instagram_drivers,
      linkedin_drivers,
      newspapers_drivers,
    }
  ) {
    return await handleResponse("mutation", {
      mutation: CREATE_CONTEXT,
      variables: {
        account_id,
        name,
        twitter_drivers,
        facebook_drivers,
        instagram_drivers,
        linkedin_drivers,
        newspapers_drivers,
      },
    });
  },
  async ADD_GEO_KEYWORD({ commit }, { context_id, keyword_id, active, type }) {
    return await handleResponse("mutation", {
      mutation: ADD_GEO_KEYWORD,
      variables: { context_id, keyword_id, active, type },
    });
  },
  async DELETE_CONTEXT({ commit }, { context_id }) {
    return await handleResponse("mutation", {
      mutation: DELETE_CONTEXT,
      variables: { context_id },
      fetchPolicy: "no-cache",
    });
  },
  async UPDATE_CONTEXT({ commit }, { context_id, name }) {
    return await handleResponse("mutation", {
      mutation: UPDATE_CONTEXT,
      variables: { context_id, name },
    });
  },
  async ADD_KEYWORD_SN({ commit }, { context_id, driver, type }) {
    return await handleResponse("mutation", {
      mutation: ADD_KEYWORD,
      variables: { context_id, driver, type },
    });
  },
  async DELETE_KEYWORD_SN({ commit }, { context_id, keyword_id, type }) {
    return await handleResponse("mutation", {
      mutation: DELETE_KEYWORD,
      variables: { context_id, keyword_id, type },
    });
  },
  async ACTIVE_TOGGLE_KEYWORD(
    { commit },
    { context_id, keyword_id, type, active }
  ) {
    return await handleResponse("mutation", {
      mutation: TOGGLE_ACTIVE_KEYWORD,
      variables: { context_id, keyword_id, type, active },
      fetchPolicy: "no-cache",
    });
  },
  async ACTIVE_TOGGLE_KEYWORD_TW(
    { commit },
    { client_id, keyword_id, active }
  ) {
    return await handleResponse("mutation", {
      mutation: TOGGLE_ACTIVE_KEYWORD_TWITTER,
      variables: { client_id, keyword_id, active },
      fetchPolicy: "no-cache",
    });
  },
};
