import gql from "graphql-tag";

export const GET_IS_ADMIN = gql(`
    query isAdmin($cognitoGroup: String!) {
          isAdmin(cognitoGroup: $cognitoGroup)
      }
`);

export const GET_VALIDATE_TOKEN = gql(`query validateJWT($token: String, $ip_public: String, $ip_local: String) {
    validateJWT(token: $token, ip_public: $ip_public, ip_local: $ip_local) {
      code
      message
      messageError
      decode
      dateExpiredToken
      newToken
    }
  }
  `);

export const GEO_DRIVERS = gql(`
    query geoDrivers($social_network: String){
      geoDrivers(social_network: $social_network){
        items
        count
      }
    }
  `);
