import {
  handleResponseBackoffice,
  handlerRespAxiosMonitorLogin,
} from "../../../../lib/handleResponse";

import { GET_IS_ADMIN } from "../graphql/query";

export default {

  async IS_CLIENT_OR_USUARIO() {
    try {
      return await handlerRespAxiosMonitorLogin(
        "get",
        "monitor/is-client-or-usuario",
        ""
      );
    } catch (error) {
      console.log("[Error] in function: IS_CLIENT_OR_USUARIO", error);
      throw error;
    }
  },

  async IS_ADMIN(_,cognitoGroup) {
    try {
      return await handleResponseBackoffice("query", {
          query: GET_IS_ADMIN,
          variables: {
            cognitoGroup
          },
        }
      );
    } catch (error) {
      console.log("[Error] in function: IS_ADMIN", error);
      throw error;
    }
  },
}