
export default {
  SET_METRICS_AUDIENCE: (state, metricsAudience) => {
    state.metricsAudience = metricsAudience ;
  },
  SET_METRICS_LEAD: (state, metricsLead) => {
    state.metricsLead = metricsLead;
  },  
  SET_LIST_CURRENCY: (state, currencies) => {
    state.currencies = currencies;
  },
  SET_LIST_BRAND: (state, brands) => {
    state.brands = brands;
  },
  SET_LIST_HISTORIC_POST: (state, historicPosts) => {
    state.historicPosts = historicPosts;
  },
  SET_LIST_CONVERSIONS: (state, conversions) => {
    state.conversions = conversions;
  },
  SET_TOTAL_GRAPHIC: (state, totalGraphic) => {
    state.totalGraphic = totalGraphic;
  }  
};
