import Vue from "vue";
import VueApollo from "vue-apollo";
import { createApolloClient } from "vue-cli-plugin-apollo/graphql-client";
import { createHttpLink } from "apollo-link-http";
import { ApolloLink } from "apollo-link";
import { InMemoryCache } from "apollo-cache-inmemory"
// Install the vue plugins
Vue.use(VueApollo);

// Name of the sessionStorage item
const AUTH_TOKEN = sessionStorage.getItem('token');

// Http endpoint
const httpEndpoint = process.env.API_GRAPHGQL_LISTENING; /* "http://localhost:3000/graphql" */ /* "http://localhost:5000/dev/graphql" */

const httpLink = createHttpLink({
  uri: httpEndpoint,
});

const link = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      api_key: process.env.API_KEY_LISTENING,
      authorization: `Bearer ${sessionStorage.getItem('token')}|${sessionStorage.getItem('refresh')}|${sessionStorage.getItem("ip_public")}|${sessionStorage.getItem("ip_local")}`,
    },
    fetchOptions: {
      mode: "cors",
    },
  });
  return forward(operation, httpLink);
});

// Config
const defaultOptions = {
 // You can use `https` for secure connection (recommended in production)
 httpEndpoint,
 // You can use `wss` for secure connection (recommended in production)
 // Use `null` to disable subscriptions
 wsEndpoint: null,
 // LocalStorage token
 tokenName: AUTH_TOKEN,
 // Enable Automatic Query persisting with Apollo Engine
 persisting: false,
 // Use websockets for everything (no HTTP)
 // You need to pass a `wsEndpoint` for this to work
 websocketsOnly: false,
 // Is being rendered on the server?
 ssr: false,

 // Override default apollo link
 // note: don't override httpLink here, specify httpLink options in the
 // httpLinkOptions property of defaultOptions.
 link,

 // Override default cache
 // cache: myCache
 cache: new InMemoryCache(),
};

// Call this in the Vue app file
export function createProvider(options = {}) {
  // Create apollo client
  const { apolloClient } = createApolloClient({
    ...defaultOptions,
    ...options,
  });

  // Create vue apollo provider
  return new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        /* fetchPolicy: "cache-and-network", */
      },
    },
    errorHandler(error) {
      // eslint-disable-next-line no-console
      console.log(
        "%cError",
        "backgrounds: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;",
        error.message
      );
    },
  });
}

export const apollo = createProvider().defaultClient;
