import Vue from "vue";
import Vuex from "vuex";
import settings from "./settings/index";
import marketTrends from "./market-trends/index";
import login from "./login/index";
import global from "./global/index";
import overview from "./overview/index";
import topNavbar from "./top-navbar/index";
import campaign from "./campaign/index";


Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    settings,
    marketTrends,
    login,
    global,
    overview,
    topNavbar,
    campaign
  }
});
