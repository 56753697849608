import { handlerRespAxiosWithoutCredentials } from "../../../../lib/handleResponse";

export default {
  //* POSTS OR UPDATES 
  async SIGNUP_CLIENT(_, data) {
    try {
      return await handlerRespAxiosWithoutCredentials("post", "auth/sign-up", data);
    } catch (error) {
      console.log("[Error] in function: SIGNUP_CLIENT", error);
      throw error;
    }
  },
  async CONFIRMATION_CLIENT(_, data) {
    try {
      return await handlerRespAxiosWithoutCredentials("post", "auth/confirmation-code", data);
    } catch (error) {
      console.log("[Error] in function: CONFIRMATION_CLIENT", error);
      throw error;
    }
  },
  async FORGOT_PASSWORD(_, data) {
    try {
      return await handlerRespAxiosWithoutCredentials("post", "auth/forgot-password", data);
    } catch (error) {
      console.log("[Error] in function: CONFIRMATION_CLIENT", error);
      throw error;
    }
  },
  async CONFIRMATION_FORGOT_PASSWORD(_, data) {
    try {
      return await handlerRespAxiosWithoutCredentials("post", "auth/confirmation-forgot-password", data);
    } catch (error) {
      console.log("[Error] in function: CONFIRMATION_CLIENT", error);
      throw error;
    }
  },
  async SIGN_IN_USER(_, dataSignIn) {
    try {
      const { data } =  await handlerRespAxiosWithoutCredentials("post", "auth/sign-in", dataSignIn);
      return data;
    } catch (error) {
      console.log("[Error] in function: SIGN_IN_CLIENT", error);
      throw error;
    }
  },
}