export default {
  loader: false,
  msgImgOffGraphic: "There is not enough data to create a graphic.",
  msgImgOff: "There is not enough data to show.",
  titleImgOff: "Image off",
  isNoAuth: false, 
  msgNoAuth: {
    title: "Unauthorized",
    message: "Unauthorized, it will redirect to login.",
  },
  drivers: null
};
