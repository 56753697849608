import { handlerRespAxiosMonitorCampaign } from "../../../../lib/handleResponse";
export default {
  //* QUERIES
  async GET_ALL_CAMPAIGNS(_, { id_marca, clientId, page }) {
    try {
      const { data } = await handlerRespAxiosMonitorCampaign(
        "get",
        `/campaign/buscarPorMarca/${id_marca}/${clientId}/?page=${page}`,
        null
      );
      return data.data;
    } catch (error) {
      console.log("[Error] in function: SEARCH_AS_BRAND ", error);
      throw error;
    }
  },
  async GET_LIST_BRAND({ commit }, { clientId }) {
    try {
      const { data } = await handlerRespAxiosMonitorCampaign(
        "get",
        `/campaign/marcas/${clientId}`,
        null
      );
      const brands = data.data;
      commit("SET_LIST_BRAND", brands);
    } catch (error) {
      console.log("[Error] in function: GET_LIST_BRAND ", error);
      throw error;
    }
  },
  async GET_DETAIL_CAMPAIGN(_, idCampaign) {
    try {
      const { data } = await handlerRespAxiosMonitorCampaign(
        "get",
        `/campaign/detail/${idCampaign}`,
        null
      );
      return data.data;
    } catch (error) {
      console.log("[Error] in function: GET_DETAIL_CAMPAIGN ", error);
      throw error;
    }
  },
  async GET_LIST_METRIC_BY_TYPE(_, type) {
    try {
      const { data } = await handlerRespAxiosMonitorCampaign(
        "post",
        `/campaign/listMetricsDescription`,
        {
          description: type,
        }
      );
      return data.data;
    } catch (error) {
      console.log("[Error] in function: GET_LIST_METRIC_BY_TYPE ", error);
      throw error;
    }
  },
  async GET_METRICS_AUDIENCE({ commit, dispatch }) {
    try {
      const type = "Audiencie";
      const response = await dispatch("GET_LIST_METRIC_BY_TYPE", type);
      commit("SET_METRICS_AUDIENCE", response);
    } catch (error) {
      console.error("[Error] in function: GET_METRICS_AUDIENCE ", error);
      throw error;
    }
  },
  async GET_METRICS_LEAD({ commit, dispatch }) {
    try {
      const type = "Leads";
      const response = await dispatch("GET_LIST_METRIC_BY_TYPE", type);
      commit("SET_METRICS_LEAD", response);
    } catch (error) {
      console.error("[Error] in function: GET_METRICS_AUDIENCE ", error);
      throw error;
    }
  },
  async GET_LIST_CURRENCY({ commit }) {
    try {
      const { data } = await handlerRespAxiosMonitorCampaign(
        "get",
        `/campaign/listCurrency`,
        null
      );
      const currencies = data.data;
      commit("SET_LIST_CURRENCY", currencies);
    } catch (error) {
      console.log("[Error] in function: GET_LIST_METRIC_BY_TYPE ", error);
      throw error;
    }
  },
  async GET_SOCIAL_MEDIA({ commit }, idCampaign) {
    try {
      const { data } = await handlerRespAxiosMonitorCampaign(
        "get",
        `/campaign/redessocialesconv/${idCampaign}`,
        null
      );
      return data.data;
    } catch (error) {
      console.log("[Error] in function: GET_SOCIAL_MEDIA ", error);
      throw error;
    }
  },
  async GET_LIST_HISTORIC_POST({ commit }, dataSend) {
    try {
      const { data } = await handlerRespAxiosMonitorCampaign(
        "post",
        `/campaign/buscarHistoricoCampRedSocial`,
        dataSend
      );
      const historicPosts = data.data;
      commit("SET_LIST_HISTORIC_POST", historicPosts);
    } catch (error) {
      console.log("[Error] in function: GET_LIST_HISTORIC_POST ", error);
      throw error;
    }
  },
  async GET_LIST_COINS() {
    try {
      const { data } = await handlerRespAxiosMonitorCampaign(
        "post",
        `/campaign/postMonedas`,
        null
      );
      return data.data;
    } catch (error) {
      console.log("[Error] in function: GET_LIST_COINS ", error);
      throw error;
    }
  },
  async GET_SUMMARIZED_TOTAL_CAMPAIGNS(_, brandId) {
    try {
      const { data } = await handlerRespAxiosMonitorCampaign(
        "get",
        `/campaign/getResumenTotal/${brandId}`,
        null
      );
      return data.data;
    } catch (error) {
      console.log("[Error] in function: GET_LIST_COINS ", error);
      throw error;
    }
  },
  async GET_RESUME_TOTAL(_, { idBrand, idCoin }) {
    try {
      const { data } = await handlerRespAxiosMonitorCampaign(
        "get",
        `/campaign/listatotal/${idBrand}/${idCoin}`,
        null
      );
      return data.data;
    } catch (error) {
      console.log("[Error] in function: GET_LIST_COINS ", error);
      throw error;
    }
  },
  async GET_SUM_POST_HIST(_, { idCampaign, dateStart, dateEnd}) {
    try {
      const { data } = await handlerRespAxiosMonitorCampaign(
        "get",
        `/campaign/sumPostHistCamps/${idCampaign}?fec_ini=${dateStart}&fec_fin=${dateEnd}`,
        null
      );
      return data.data;
    } catch (error) {
      console.log("[Error] in function: GET_SUM_POST_HIST ", error);
      throw error;
    }
  },
  async GET_LIST_SOCIAL_CAMPAIGN_ONE(_, idCampaign) {
    try {
      const { data } = await handlerRespAxiosMonitorCampaign(
        "get",
        `/campaign/listarRedSocCampaniaOne/${idCampaign}`,
        null
      );
      return data.data;
    } catch (error) {
      console.log("[Error] in function: GET_LIST_SOCIAL_CAMPAIGN_ONE", error);
      throw error;
    }
  },
  async GET_LIST_CONVERSIONS({ commit }, {idCampaign, idSocialMedia}) {
    try {
      const { data } = await handlerRespAxiosMonitorCampaign(
        "get",
        `/campaign/conversiones/${idCampaign}/${idSocialMedia}`,
        null
      );
      const conversions = data.data;
      commit("SET_LIST_CONVERSIONS", conversions);
    } catch (error) {
      console.log("[Error] in function: GET_LIST_CONVERSIONS", error);
      throw error;
    }
  },
  async GET_LIST_POST_DATE_ID(_, idCampaign) {
    try {
      const { data } = await handlerRespAxiosMonitorCampaign(
        "get",
        `/campaign/listPostDateID/${idCampaign}`,
        null
      );
      return data.data;
    } catch (error) {
      console.log("[Error] in function: GET_LIST_SOCIAL_CAMPAIGN_ONE", error);
      throw error;
    }
  },
  async GET_TOTAL_GRAPHIC({ commit }, idCampaign) {
    try {
      const { data } = await handlerRespAxiosMonitorCampaign(
        "get",
        `/campaign/getGraficoTotalizado/${idCampaign}`,
        null
      );
      const totalGraphic = data.data;
      commit("SET_TOTAL_GRAPHIC", totalGraphic);
    } catch (error) {
      console.log("[Error] in function: GET_LIST_CONVERSIONS", error);
      throw error;
    }
  },
  //* MUTATIONS
  async UPDATE_CAMPAIGN(_, { idCampaign, campaign }) {
    try {
      delete campaign.table;
      const { data } = await handlerRespAxiosMonitorCampaign(
        "put",
        `/campaign/${idCampaign}`,
        {
          data: campaign,
        }
      );
      return data.data;
    } catch (error) {
      console.log("[Error] in function: UPDATE_CAMPAIGN ", error);
      throw error;
    }
  },
};
