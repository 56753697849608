<template>
  <div>
    <isc-modal-alerts
      v-model="isNoAuth"
      :title="titleNoAuth"
      :paragraph="paragraph"
      :icon="icon"
      :iconColor="iconColor"
      :showBtn="showBtn"
      :showBtnClose="true"
      :showMainIcon="showMainIcon"
      :buttonMessage="buttonMessage"
      :noCloseBackdrop="true"
      @sendResult="redirectToLogin"
      @input="redirectToLogin"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import IscModalAlerts from "./IscModalAlerts.vue";
import { alertSetting } from "../../mixins/alertSetting";
export default {
  name: "CpIscModalNoAuth",
  components: {
    IscModalAlerts,
  },
  mixins: [alertSetting],
  data() {
    return {
      titleNoAuth: ""
    };
  },
  computed: {
    ...mapState("global/helper", [
      "isNoAuth",
    ]),
    ...mapGetters("global/helper", ["getMsgNoAuth"]),
  },
  watch: {
    getMsgNoAuth: {
      handler: function (data) {
        this.titleNoAuth = data.title;
        this.errorModal(data.message);
      },
      immediate: true, // Trigger the watcher immediately on component mount, if needed
    },
  },
  methods: {
    ...mapActions({
      closeModal: "global/helper/CLOSE_MODAL_NO_AUTH",
    }),
    redirectToLogin() {
      this.closeModal();
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = `${process.env.URL_MONITOR}/login`;
    },
  },
};
</script>
