<template>
  <div>
    <b-modal
      v-model="model"
      size="lg"
      title=""
      hide-footer
      :no-enforce-focus="true"
      :no-close-on-backdrop="noCloseBackdrop"
    >
      <div v-if="showBtnClose">
        <CloseButton @close="close" />
      </div>
      <div class="container-alerts">
        <div class="flex-center ">
          <i
            v-if="showMainIcon"
            class="flex-center icon"
            :class="icon"
            :style="{ color: iconColor }"
          ></i>
          <div 
            class="spinner"
            v-else
          >
            <b-spinner 
              variant="primary" 
              label="Spinning"
            >
            </b-spinner>
          </div>
        </div>
        <p class="flex-center paragraph title">
          <strong>{{ title }}</strong>
        </p>
        <p class="flex-center paragraph" data-cy="message-alert">{{ paragraph }}</p>
        <div
          v-if="showBtn" 
          class="flex-center w-100 container-button"
        >
          <b-button
            variant="primary"
            class="px-3 paragraph button"
            @click="sendResult"
            >{{ buttonMessage }}</b-button
          >
        </div>
        <div v-else class="container-button"></div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import CloseButton from "../components/button/CloseButton.vue";
export default {
  name: "IscModalAlerts",
  components: {
    CloseButton,
  },
  props: {
    showBtn: {
      type: Boolean,
      default: true
    },
    showBtnClose: {
      type: Boolean,
      default: true
    },
    showMainIcon: {
      type: Boolean,
      default: true
    },
    show: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
    iconColor: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    paragraph: {
      type: String,
      default: "",
    },
    buttonMessage: {
      type: String,
      default: "",
    },
    value: {
      type: Boolean,
      default: false,
    },
    refresh: {
      type: Boolean,
      default: false,
    },
    noCloseBackdrop: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    sendResult() {
      this.$emit("sendResult", false);
    },
    close(value) {
      this.$emit("input", value);
      if (this.refresh) this.sendResult();
    },
  },
};
</script>
<style scoped>
::v-deep .modal-dialog {
  width: 566px
}
::v-deep .modal-content {
  height: 274px;
}
::v-deep .modal-header {
  display: none !important;
}

::v-deep .close {
  font-size: 30px !important;
}

::v-deep .modal-body {
  padding-top: 0rem;
}

::v-deep .modal {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.container-alerts {
  padding-left: 2rem;
  padding-right: 2rem;
  
}
.spinner{
  padding-top: 44px;
}
.container-alerts .icon {
  font-size: 28px;
}

.container-alerts .paragraph {
  font-family: "Oxygen";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  color: #2c3852;
}

.container-alerts .title {
  font-size: 20px;
  font-weight: 700;
  padding-top: 1rem;
  padding-bottom: 1.5rem;
}

.container-alerts .container-button {
  padding-top: 30px;
  padding-bottom: 1.1rem;
}

.container-alerts .button {
  color: #fff;
  font-size: 14px;
  font-weight: 700px;
}
</style>
