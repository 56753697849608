import {
  handleResponse, // this is for graphql listen
  handleResponseBackoffice,
  handlerRespAxiosMonitorLogin,
} from "../../../../lib/handleResponse";

import { GET_IS_ADMIN, GET_VALIDATE_TOKEN, GEO_DRIVERS } from "../graphql/query";

export default {
  async IS_CLIENT_OR_USUARIO() {
    try {
      return await handlerRespAxiosMonitorLogin(
        "get",
        "monitor/is-client-or-usuario",
        ""
      );
    } catch (error) {
      console.log("[Error] in function: IS_CLIENT_OR_USUARIO", error);
      throw error;
    }
  },

  async IS_ADMIN(_, cognitoGroup) {
    try {
      return await handleResponseBackoffice("query", {
        query: GET_IS_ADMIN,
        variables: {
          cognitoGroup,
        },
      });
    } catch (error) {
      console.log("[Error] in function: IS_ADMIN", error);
      throw error;
    }
  },
  async GET_VALIDATE_TOKEN(_, { token, ip_public, ip_local }) {
    try {
      return await handleResponse("query", {
        query: GET_VALIDATE_TOKEN,
        variables: {
          token,
          ip_public,
          ip_local,
        },
      });
    } catch (error) {
      console.log("[Error] in function: GET_VALIDATE_TOKEN", error);
      throw error;
    }
  },
  async GET_GEO_DRIVERS({ commit },) {
    try {
      const response = await handleResponse("query", {
        query: GEO_DRIVERS,
        variables: {
          social_network: "fb"
        },
      });
      commit("SET_DRIVERS", response.data.geoDrivers.items.data);
    } catch (error) {
      console.log("[Error] in function: GET_GEO_DRIVERS", error);
      throw error;
    }
  },

  async RENEW_TOKEN(_, { refreshToken }) {
    try {
      return await handlerRespAxiosMonitorLogin(
        "post",
        "security/renew-token",
        { refreshToken }
      );
    } catch (error) {
      console.log("[Error] in function: RENEW_TOKEN", error);
      throw error;
    }
  },
  TRIGGER_MODAL_NO_AUTH({ commit }) {
    commit("SHOW_MODAL_NO_AUTH");
  },
  CLOSE_MODAL_NO_AUTH({ commit }) {
    commit("HIDE_MODAL_NO_AUTH");
  },
  MSG_NO_AUTH({ commit }, msgNoAuth) {
    commit("SET_MSG_NO_AUTH", msgNoAuth);
  }
};
