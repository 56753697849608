import {
  handlerRespAxiosMonitorLogin,
  handlerRespAxiosWithoutCredentials,
} from "../../../../lib/handleResponse";

export default {
  //* QUERIES
  //* The most services come from BACK-LOGIN
  async GET_ALL_ACCOMMODATION() {
    try {
      return await handlerRespAxiosMonitorLogin(
        "get",
        "user/allAccommodation",
        ""
      );
    } catch (error) {
      console.log("[Error] in function: GET_ALL_ACCOMMODATION", error);
    }
  },
  async GET_ALL_HOUR_ZONE() {
    try {
      return await handlerRespAxiosWithoutCredentials(
        "get",
        "user/allZonaHoraria",
        ""
      );
    } catch (error) {
      console.log("[Error] in function: GET_ALL_HOUR_ZONE ", error);
    }
  },
  async GET_ALL_COUNTRIES() {
    try {
      return await handlerRespAxiosWithoutCredentials(
        "get",
        "user/allPais",
        ""
      );
    } catch (error) {
      console.log("[Error] in function: GET_ALL_COUNTRIES ", error);
    }
  },
  async GET_ALL_LANGUAGE() {
    try {
      return await handlerRespAxiosMonitorLogin("get", "user/allIdioma", "");
    } catch (error) {
      console.log("[Error] in function: GET_ALL_LANGUAGE ", error);
    }
  },
  /**
   * get Client info by username
   * Table T_USUARIO
   * @param {*} _
   * @param {*} data
   * @return {*}
   */
  async GET_INFO_USER(_, data) {
    try {
      return await handlerRespAxiosWithoutCredentials(
        "post",
        "user/getUserbyId",
        data
      );
    } catch (error) {
      console.log("[Error] in function: GET_INFO_USER ", error);
      throw error;
    }
  },
  async GET_INFO_CLIENT_BY_ID(_, idClient) {
    try {
      return await handlerRespAxiosWithoutCredentials(
        "get",
        "user/get-client-by-id",
        {
          idClient,
        }
      );
    } catch (error) {
      console.log("[Error] in function: GET_INFO_USER ", error);
      throw error;
    }
  },

  //* ====== QUERIES USUARIOS TABLE =====
  /**
   * get all data Usuario
   * this is from schema: monitor, table: M_USUARIO
   * @param {*} _
   * @param {*} dscUsername
   * @return {*}
   */
  async GET_INFO_USUARIO_BY_USERNAME(_, dscUsername) {
    try {
      const {
        data: { data },
      } = await handlerRespAxiosMonitorLogin(
        "get",
        "usuario/get-usuario-by-username",
        {
          dscUsername,
        }
      );
      return data.data;
    } catch (error) {
      console.log("[Error] in function: GET_INFO_USUARIO_BY_USERNAME", error);
      throw error;
    }
  },
  /**
   **Get all data Usuario
   * it uses the tokens to validate the if the client, the it brings
   * all data from M:USUARIOS, otherwise message error
   * @return {*}
   */
  async GET_USUARIO_LIST() {
    try {
      return await handlerRespAxiosMonitorLogin(
        "get",
        "usuario/get-list-usuarios",
        ""
      );
    } catch (error) {
      console.log("[Error] in function: GET_USUARIO_LIST", error);
      throw error;
    }
  },
  //* END QUERIES USUARIOS TABLE
  /**
   *
   * Get User if it is in table T_USUARIO OR M:USUARIOS
   * @param {*} _
   * @param {*} dataUser
   * @return {*}
   */
  async GET_USER_BY_USERNAME(_, dataUser) {
    try {
      const { data } = await handlerRespAxiosWithoutCredentials(
        "get",
        "user/get-user-by-username",
        dataUser
      );
      return data;
    } catch (error) {
      console.log("[Error] in function: GET_USER_BY_USERNAME ", error);
      throw error;
    }
  },
  /**
   *
   * Get User if it is in table T_USUARIO OR M:USUARIOS
   * @param {*} _
   * @param {*} dataUser
   * @return {*}
   */
  async GET_USER_BY_EMAIL(_, dataUser) {
    try {
      const { data } = await handlerRespAxiosWithoutCredentials(
        "get",
        "user/get-user-by-email",
        dataUser
      );
      return data;
    } catch (error) {
      console.log("[Error] in function: GET_USER_BY_EMAIL ", error);
      throw error;
    }
  },

  async GET_OBJ_CURRENT_USER({ commit }) {
    try {
      const userName = sessionStorage.getItem("username");
      const dscUsername = { DSC_USERNAME: userName };
      const {
        data: { obj },
      } = await handlerRespAxiosWithoutCredentials(
        "post",
        "user/getUserbyId",
        dscUsername
      );

      commit("SET_INFO_USER", obj);
    } catch (error) {
      console.log("[Error] in function: GET_INFO_USER ", error);
    }
  },
  //* ===== POSTS OR UPDATES (MUTATIONS) ====
  async UPDATE_USER_BY_CLIENT(_, data) {
    try {
      return await handlerRespAxiosMonitorLogin("put", "user/updateUser", data);
    } catch (error) {
      console.log("[Error] in function: UPDATE_USER_BY_CLIENT ", error);
    }
  },
  async CREATE_CLIENT(_, data) {
    try {
      return await handlerRespAxiosWithoutCredentials(
        "post",
        "client/createClient",
        data
      );
    } catch (error) {
      console.log("[Error] in function: CREATE_CLIENT", error);
    }
  },
  async USER_REGISTER(_, data) {
    try {
      return await handlerRespAxiosWithoutCredentials(
        "post",
        "user/register",
        data
      );
    } catch (error) {
      console.log("[Error] in function: USER_REGISTER", error);
    }
  },
  async UPDATE_USER_BY_LOGIN(_, data) {
    try {
      return await handlerRespAxiosWithoutCredentials(
        "put",
        "user/updateUserByLogin",
        data
      );
    } catch (error) {
      console.log("[Error] in function: UPDATE_USER_BY_LOGIN", error);
    }
  },
  //* ===== POSTS  MUTATIONS USUARIOS  ====
  async CREATE_USUARIO(_, data) {
    try {
      return await handlerRespAxiosMonitorLogin(
        "post",
        "auth/signup-user",
        data
      );
    } catch (error) {
      console.log("[Error] in function: CREATE_USUARIO", error);
      throw error;
    }
  },
  /**
   ** Update USUARIO into monitor."M_USUARIO" and cognito
   * @param {*} _
   * @param {*} data
   * @return {*} 
   */
  async UPDATE_USUARIO(_, data) {
    try {
      return await handlerRespAxiosMonitorLogin(
        "put",
        "auth/update-usuario",
        data
      );
    } catch (error) {
      console.log("[Error] in function: UPDATE_USUARIO", error);
      throw error;
    }
  },
  /**
   ** DELETE USUARIO from cognito and monitor."M_USUARIO"
   * @param {*} _
   * @param {*} data
   * @return {*}
   */
  async DELETE_USUARIO(_, data) {
    try {
      return await handlerRespAxiosMonitorLogin(
        "delete",
        "auth/delete-usuario",
        data
      );
    } catch (error) {
      console.log("[Error] in function: DELETE_USUARIO", error);
      throw error;
    }
  },
  //* ===== END USUARIOS ====

  //* ===== COGNITO SERVICE  ====
  async CHANGE_PASSWORD_COGNITO(_, data) {
    try {
      return await handlerRespAxiosMonitorLogin(
        "post",
        "auth/change-password",
        data
      );
    } catch (error) {
      console.log("[Error] in function: CHANGE_PASSWORD_COGNITO", error);
      throw error;
    }
  },
  //* ===== END COGNITO SERVICE  ====
  //* SETTING VALUES
  SET_INFO_USER({ commit }, infoUser) {
    commit("SET_INFO_USER", infoUser);
  },
};
